import React, { useState } from "react";
import { Button, Input } from "@chakra-ui/react";

interface Props {
  loading: boolean;
  onSubmit: ({ email, password }: { email: string; password: string }) => void;
}

export default function LoginForm({ loading, onSubmit }: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const onSubmitForm = (e) => {
    e.preventDefault();
    onSubmit({ email, password });
  };
  return (
    <form onSubmit={onSubmitForm}>
      <Input
        type="email"
        my={3}
        placeholder="メールアドレス"
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        type="password"
        my={3}
        placeholder="パスワード"
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        w="100%"
        colorScheme="pink"
        isLoading={loading}
        isDisabled={email === "" || password === ""}
      >
        ログインする
      </Button>
    </form>
  );
}
