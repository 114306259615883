import React from "react";
import { Box, Spinner } from "@chakra-ui/react";
import CommonLayout from "@src/layouts/CommonLayout";

export default function PageLoading() {
  return (
    <CommonLayout>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        width="100%"
        height="100%"
        backgroundColor="rgba(0, 0, 0, 0.3)"
      >
        <Spinner
          mb={180}
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="pink.500"
          size="xl"
        />
      </Box>
    </CommonLayout>
  );
}
