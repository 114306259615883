import React, { useEffect, useState } from "react";
import { NextPage } from "next";
import { useRouter } from "next/router";
import firebase from "firebase";
import { Box, Button, Heading, Stack, Text, useToast } from "@chakra-ui/react";
import { FaApple, FaGoogle } from "react-icons/fa";
import { firebaseClient } from "@src/services/firebase/client";
import CommonLayout from "@src/layouts/CommonLayout";
import SignInForm from "@src/components/LoginForm";
import { routes } from "@src/constants/appRoutes";
import PageLoading from "@src/layouts/PageLoading";
import { getOriginalUrl, setOriginalUrl } from "@src/lib/originalUrl";
import Link from "next/link";
import { customFetch } from "@src/lib/customfetch";
import { FAST_LANE_API_URL } from "@src/constants/api";

const LoginPage: NextPage = () => {
  const router = useRouter();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(null);

  const auth = firebaseClient.auth();
  const googleProvider = new firebaseClient.auth.GoogleAuthProvider();
  const appleProvider = new firebaseClient.auth.OAuthProvider("apple.com");
  const twitterProvider = new firebaseClient.auth.TwitterAuthProvider();

  if (router.query.originalUrl) {
    setOriginalUrl(router.query.originalUrl as string);
  }

  const onSubmitEmailLogin = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .catch((e) => {
        toast({
          position: "top-right",
          title: `${e}`,
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickSocialLogin = (provider: firebase.auth.AuthProvider) => {
    auth.signInWithRedirect(provider);
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user && typeof window !== "undefined") {
        if (!user.emailVerified) {
          router.push(routes.requireEmailVerification());
          return;
        }
        user.getIdToken(true).then((idToken) => {
          console.log("idtoken: ", idToken);
          customFetch(`${FAST_LANE_API_URL}/auth/session`, {
            method: "post",
            body: JSON.stringify({ idToken }),
          })
            .then((data) => {
              router.push(getOriginalUrl() || routes.userProfile());
            })
            .catch((e) => {
              toast({
                position: "top-right",
                title: "エラーが発生しました。再度お試し下さい",
                status: "error",
                isClosable: true,
              });
            });
        });
      } else {
        setAuthenticated(false);
      }
    });
  }, []);

  if (authenticated === null) {
    return <PageLoading />;
  }

  return (
    <CommonLayout>
      <Box
        display="flex"
        flexDirection="column"
        m={3}
        mx="auto"
        mb={50}
        maxW={600}
      >
        <Heading
          display="inline-block"
          m={3}
          my={[5, 10]}
          pb={3}
          fontWeight="bold"
          fontSize={24}
          textAlign="center"
          borderBottom="3px solid"
        >
          ログイン
        </Heading>
        <Box m={5}>
          <Heading mb={5} fontWeight="bold" fontSize={18} textAlign="center">
            メールアドレスでログイン
          </Heading>
          <SignInForm loading={loading} onSubmit={onSubmitEmailLogin} />
        </Box>
        <Box m={5}>
          <Heading mb={5} fontWeight="bold" fontSize={18} textAlign="center">
            ソーシャルアカウントでログイン
          </Heading>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Stack>
              <Button onClick={() => onClickSocialLogin(googleProvider)}>
                <FaGoogle />
                <Text ml={2}>Googleでログイン</Text>
              </Button>
              {/* <Button onClick={() => onClickSocialLogin(appleProvider)}>
                <FaApple />
                <Text ml={2}>Appleでログイン</Text>
              </Button> */}
              {/* TODO: Enable twitter login after enough tests */}
              {/*<Button onClick={() => onClickSocialLogin(twitterProvider)}>*/}
              {/*  <FaTwitter />*/}
              {/*  <Text ml={2}>Twitterでログイン</Text>*/}
              {/*</Button>*/}
            </Stack>
          </Box>
        </Box>
        <Box textAlign="center">
          <Link href={routes.signUp()}>
            <a>
              <Text color="pink.500">新規登録はこちら</Text>
            </a>
          </Link>
        </Box>
      </Box>
    </CommonLayout>
  );
};

export default LoginPage;
