import fetch from "isomorphic-unfetch";

export const customFetch = async (url: string, props: RequestInit) => {
  const res = await fetch(url, {
    ...props,
    mode: "cors",
    credentials: "include",
    headers: { "X-Requested-By": "lets-meal-web-client" },
  });
  return await res.json();
};
